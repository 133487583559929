.refund-policy-page {
    background-color: #f8f9fa;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2rem 0;
    font-family: 'Arial', sans-serif;
  }
  
  .refund-policy-container {
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 2rem;
    max-width: 450px;
    width: 100%;
    margin: 0 auto;
  }
  
  .refund-policy-header {
    text-align: center;
    margin-bottom: 2rem;
  }
  
  .refund-policy-title {
    color: #2c3e50;
    font-size: 1.8rem;
    margin-bottom: 0.5rem;
  }
  
  .refund-policy-last-updated {
    color: #7f8c8d;
    font-size: 0.8rem;
    font-style: italic;
  }
  
  .refund-policy-content {
    color: #34495e;
  }
  
  .refund-policy-intro {
    font-size: 1rem;
    line-height: 1.6;
    margin-bottom: 1.5rem;
  }
  
  .refund-policy-list {
    list-style-type: none;
    padding-left: 0;
  }
  
  .refund-policy-list-item {
    position: relative;
    padding-left: 1.5rem;
    margin-bottom: 1.5rem;
    line-height: 1.6;
    font-size: 0.9rem;
  }
  
  .refund-policy-list-item::before {
    content: '•';
    color: #3498db;
    font-size: 1.2rem;
    position: absolute;
    left: 0;
    top: 0;
  }
  
  @media (max-width: 480px) {
    .refund-policy-container {
      padding: 1.5rem;
    }
  
    .refund-policy-title {
      font-size: 1.5rem;
    }
  
    .refund-policy-intro {
      font-size: 0.9rem;
    }
  
    .refund-policy-list-item {
      font-size: 0.8rem;
    }
  }