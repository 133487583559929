.mobileForgotPassword__wrapper {
  background: #fff;
  .mobileForgotPassword__header {
    box-shadow: 0px -6px 13px 0px #666666;
    padding: 15px 15px;
    .tpu__header__widgets {
      display: flex;
      justify-content: space-between;
      .leftArrowIcon {
        user-select: none;
        svg {
          color: #000;
          font-family: $font-family;
          cursor: pointer;
          margin-bottom: -6px;
        }
      }
      .tpu__header__name {
        display: flex;
        align-items: center;
        flex-direction: column;
        gap: 2px;
        h2 {
          color: #000;
          font-family: $font-family;
          font-size: 18px;
          font-weight: 600 !important;
        }
      }
      .questionIcon {
        svg {
          font-size: 20px;
          font-family: $font-family;
          color: $white-bg-color;
          visibility: hidden;
        }
      }
    }
  }
  .mobileForgotPassword__content__area {
    padding: 15px;
    padding-top: 25px;
    .mobileForgotPassword__heading {
      h2 {
        font-size: 25px;
        color: #000;
        font-family: $font-family;
        margin-bottom: 10px !important;
        font-weight: 600 !important;
      }
      p {
        color: #000;
        font-size: 13px;
        font-weight: 600 !important;
      }
    }
    .mobileForgotPassword__image {
      img {
        width: 100%;
      }
    }
    .mobileForgotPassword__inputField {
      margin-top: 20px;
      label {
        color: #000;
        font-size: 13px;
        font-family: $font-family;
        margin-bottom: 5px;
      }
      .mobileNumberField {
        background: #f4f7ff;
        border: 1px solid #ececf2;
        display: flex;
        align-items: center;
        width: 100% !important;
        padding: 7px 9px;
        border-radius: 5px;
        span {
          padding-right: 10px;
          svg {
            font-size: 15px;
            color: #000;
            margin-bottom: -3px;
          }
        }
        .mobileInput {
          border: none;
          width: 100%;
          outline: none;
          background: #f4f7ff;
        }
      }
      .submitButton {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        margin-top: 25px;
        .loginButton {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 8px;
          width: 100%;
          background: #3871ff;
          border: 1px solid #3871ff;
          outline: none;
          padding: 8px 10px;
          border-radius: 5px;
          font-family: $font-family;
          span {
            color: #fff;
            font-weight: 600 !important;
            font-family: $font-family;
            font-size: 13px;
            text-transform: uppercase;
          }
        }
      }
    }
  }
}
