.bottom_position_modal {
  position: fixed;
  background: #fff;
  border: 1px solid hsla(0deg, 0%, 88%, 0.38) !important;
  border-radius: 20px 20px 0 0;
  padding: 15px;
  width: 100% !important;
  max-width: 425px;
  height: max-content;
  max-height: 90vh;
  min-height: 20%;
  // overflow: auto;
  margin: 0 auto;
  bottom: 0;
  left: 0;
  right: 0;
  transform-origin: center;
  z-index: 999999999999;
  padding-bottom: 30px;

  @include breakpoints(largeLaptop) {
    width: 50%;
  }

  @include breakpoints(tablet) {
    width: 60%;
  }

  @include breakpoints(extralargeMobile) {
    width: 100%;
    height: max-content;
    top: 20%;
    bottom: 20px;
    transform: translateY(0);
    border-radius: 10px;
    min-height: 50% !important;
  }

  .top_hr_line {
    width: 50px;
    height: 3px;
    background-color: lightgray;
    border-radius: 5px;
    position: absolute;
    top: 10px;
    left: 50%;
    transform: translateX(-25px);
  }

  .title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 20px 0;

    h2 {
      font-size: 18px;
      font-weight: 700 !important;
      color: #000;

      @include breakpoints(semiPortraitTablet) {
        font-size: 18px;
      }
    }

    span {
      cursor: pointer;
      user-select: none;
      color: #000;
      font-weight: 900 !important;
      background-color: #fff;
      border-radius: 50%;
      position: absolute;
      top: -40px;
      right: 10px;
      z-index: 1000;
      height: 30px;
      width: 30px;
      display: flex;
      justify-content: center;
      align-items: center;

      .closeIcon {
        width: 24px;
        height: 24px;
      }
    }
  }
}

// backdrop effect
.backdrop_effect {
  position: fixed;
  background: rgb(0 0 0 / 71%);
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
  cursor: pointer;
}

.profile_edit_modal {
  .text_input_wrapper {
    margin-bottom: 16px;
  }
  .text_input {
    border: 1px solid lightgray;
    border-radius: 5px;
    width: 100%;
    position: relative;
  }
  .text_input_icon {
    width: 16px;
    height: 16px;
    position: absolute;
    top: 50%;
    left: 8px;
    transform: translateY(-8px);
    z-index: 10;
  }
  .text_input_field {
    border-radius: 5px;
    padding: 8px 9px;
    width: 100%;
    border: none;
    padding-left: 30px;
    font-weight: 600 !important;
    outline: none;
  }
  .submit_btn {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    width: 100%;
    background: green;
    border: 1px solid green;
    outline: none;
    padding: 8px 10px;
    border-radius: 20px;

    span {
      color: #fff;
      font-size: 14px;
    }
  }
}

.mobileNumberField {
  background: #f4f7ff;
  border: 1px solid #ececf2;
  display: flex;
  align-items: center;
  width: 100% !important;
  padding: 7px 9px;
  border-radius: 5px;
  span {
    padding-right: 10px;
    svg {
      font-size: 15px;
      color: #000;
      margin-bottom: -3px;
    }
  }
  .passwordInput {
    border: none;
    width: 100%;
    outline: none;
    background: #f4f7ff;
  }

  .mobileInput {
    border: none;
    width: 100%;
    outline: none;
    background: #f4f7ff;
  }
}

.delete-account {
  width: 100%;
  max-height: 50%;
  background: #fff;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  padding: 0px 20px 20px 20px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  overflow: auto;

  .del-modal-header {
    display: flex;
    justify-content: flex-end;
  }

  .del-close-button {
    font-size: 24px;
    background: none;
    border: none;
    cursor: pointer;
  }

  .del-modal-body {
    text-align: center;
  }



  .del-icon-trash {
    font-size: 40px;
    color: #e53945;
    margin-bottom: 10px;
  }

  .del-heading {
    font-family: "Roboto", sans-serif !important;
    font-weight: 700;
    font-size: 20px;
    margin-top: 5px;
    color: #000;
  }

  .del-description {
    font-family: "Roboto", sans-serif;
    font-size: 14px;
    color: gray;
    margin-top: 5px !important;
    padding-right: 50px;
    padding-left: 50px;
  }

  .del-dropdown-wrapper {
    margin: 20px auto;
    width: 90%;
  }

  .del-dropdown-select {
    width: 100%;
    height: 40px;
    border: 1px solid #eee;
    border-radius: 5px;
    padding: 5px;
    font-size: 14px;
  }

  .del-modal-actions {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }

  .del-delete-button {
    background-color: #e53945;
    color: #fff;
    border: 1px solid #e53945;
    border-radius: 20px;
    padding: 10px 20px;
    cursor: pointer !important;
    min-width: 33%;
  }

  .del-cancel-button {
    border: 1px solid #e53945;
    color: #e53945;
    background: none;
    border-radius: 20px;
    padding: 10px 20px;
    cursor: pointer;
    min-width: 33%;
  }
}

// .del-modal-overlay {
//   position: fixed;
//   top: 0;
//   left: 0;
//   width: 100%;
//   height: 100%;
//   background-color: rgba(0, 0, 0, 0.7);
//   display: flex;
//   justify-content: center;
//   align-items: flex-end;
//   z-index: 1000;
// }

.del-modal-container {
  width: 100%;
  max-height: 50%;
  background: #fff;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  padding: 20px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  overflow: auto;
}