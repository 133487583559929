.tpu__referrals__section__wrapper {
  .tpu__referrals__section__area {
    height: calc(100vh - 108px);
    overflow-y: auto;
    // padding: 5px;
    // Referral Section
    .tpu__referrals__section__text__box {
      margin-top: 10px;
      padding: 10px;
      box-shadow: 0px 0px 9px -1px #e2e2e2;
      border-radius: 5px;
      text-align: center;
      h2 {
        font-size: 18px;
        font-weight: 600 !important;
        font-family: $font-family !important;
        margin-bottom: 5px !important;
      }
      p {
        color: #000;
        font-family: $font-family !important;
        font-size: 12px;
        font-weight: 400 !important;
      }
    }
    // Referral calculate section
    .tpu__premium__section {
      margin-top: 20px;
      .tpu__premium__section__boxes {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        justify-content: center;
        align-items: center;
        gap: 10px;
        .prem__section__box {
          box-shadow: 0px 0px 9px -1px #e2e2e2;
          border-radius: 5px;
          .premium__box {
            padding: 10px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            text-align: start;
            span {
              svg {
                color: gray;
                font-size: 25px;
              }
            }
            .premium__box__name {
              p {
                color: #000;
                font-size: 10px;
                font-family: $font-family !important;
                font-weight: 500 !important;
              }
            }
          }
        }
      }
    }
    // Share Refer Link
    .tpu__shareRefer__link__section {
      margin-top: 10px;
      padding: 10px;
      box-shadow: 0px 0px 9px -1px #e2e2e2;
      border-radius: 5px;
      .tpu__shareReferLink__header {
        h2 {
          text-align: center;
          font-size: 13px;
          color: #000;
          font-weight: 600 !important;
        }
      }
      .tpu__shareReferLink__icons {
        margin-top: 10px;
        .tpu__shareReferLink__box {
          display: grid;
          grid-template-columns: repeat(4, 1fr);
          justify-content: center;
          gap: 10px;
          .tpu__shareReferLink__widget {
            text-align: center;
            span {
              cursor: pointer;
              width: 40px;
              height: 40px;
              padding: 10px;
              border-radius: 100px;
              display: flex;
              align-items: center;
              justify-content: center;
              border: 1px solid lightgray;
              margin: auto;
            }
            p {
              font-size: 11px;
              font-family: $font-family !important;
              font-weight: 500 !important;
              margin-top: 3px !important;
            }
          }
        }
      }
    }
    // Referral List
    .tpu__referral__list__section {
      margin-top: 10px;
      padding: 5px;
      background: #edeffb;
      height: 100%;
      border-top-left-radius: 15px;
      border-top-right-radius: 15px;
      .tpu__referral__list__header {
        padding-top: 5px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .tpu__referral__title {
          h2 {
            font-size: 16px;
            color: #fff;
            background: #ec5120;
            padding: 5px 8px;
            border-radius: 5px;
            border: 1px solid #ec5120;
          }
        }
        .tpu__referral__list__searchBox {
          .search__input {
            padding: 8px 8px;
            border: none;
            border-radius: 5px;
            outline: none;
            &:focus {
              border: 1px solid #0866ff;
            }
          }
        }
      }
      .tpu__referral__list__card {
        margin-top: 10px;
        .tpu__referral__tabHistory {
          margin-bottom: 10px;
          background: #fff;
          border-radius: 10px;
          padding: 15px 15px;
          color: #000;
          .tpu__referral__tabHistory__heading {
            color: #000;
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 10px;
            cursor: pointer;
            user-select: none;
            .tpu__referral__tabHistory__icon {
              
              display: flex;
              align-items: center;
              gap: 10px;
              .tpu__referral__tabIcon {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 40px;
                height: 40px;
                border-radius: 100px;
                border: 1px solid lightgray;
                img {
                  width: 40px;
                  height: 40px;
                }
              }
              .tpu__referral__tabName {
                display: flex;
                flex-direction: column;
                gap: 2px;
                h2 {
                  font-size: 15px;
                  font-family: $font-family;
                  font-weight: 600 !important;
                }
                p {
                  color: #797979;
                  font-size: 13px;
                  font-weight: bold !important;
                  font-family: $font-family;
                }
              }
            }
            .tpu__referral__tabDetails{
              color: #7b7b7b;
            }
            .tpu__referral__tabHistory__amount {
              display: flex;
              justify-content: flex-end;
              align-items: center;
              gap: 10px;
              .tpu__referral__tabPrice {
                text-align: end;
                span {
                  padding: 2px 4px;
                  border-radius: 4px;
                  color: #fff;
                  width: max-content;
                  font-size: 11px;
                  font-family: $font-family;
                  text-transform: capitalize;
                  margin-bottom: 5px !important;
                  border: 1px solid #000;
                }
                h3 {
                  font-size: 11px;
                  font-family: $font-family;
                  font-weight: 600 !important;
                  
                }
                p {
                  margin-top: 2px !important;
                  font-size: 12px;
                  font-family: $font-family;
                  // font-weight: 300 !important;
                }
              }
              .tpu__icon {
                span {
                  svg {
                    font-size: 20px;
                    cursor: pointer;
                  }
                }
              }
            }
          }
          .tpu___referral__tabHistory__details {
            margin-top: 5px;
            padding: 15px 0;
            // display: none;
            .tpu__referral__tabHistoryDetails__heading {
              text-align: center;
              border-bottom: 1px dashed lightgray;
              padding-bottom: 10px;
              .tpu__referral__tabIcon {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 50px;
                height: 50px;
                border-radius: 100px;
                border: 1px solid lightgray;
                margin: auto;
                img {
                  width: 50px;
                  height: 50px;
                }
              }
              .tpu__referral__details__price {
                h2 {
                  font-size: 14px;
                  margin-top: 5px !important;
                  font-weight: 600 !important;
                  font-family: $font-family;
                }
                p {
                  
                  font-size: 14px;
                  font-family: $font-family;
                }
              }
            }

            .tpu__referral__tabHistory___content {
              .tpu__tabHistory__content {
                display: flex;
                align-items: center;
                justify-content: space-between;
                border-bottom: 1px solid lightgray;
                padding: 8px 5px;
                font-family: $font-family;
                font-size: 13px;
                span {
                  color: rgb(112, 112, 112)
                }
                strong {
                  font-weight: 600 !important;
                  text-align: end;
                  .tpu__tabHistory__status {
                    
                    span {
                      padding: 2px 4px;
                      border-radius: 4px;
                      color: #fff;
                      width: max-content;
                      font-size: 11px;
                      font-family: $font-family;
                      text-transform: capitalize;
                      margin-bottom: 5px !important;
                      border: 1px solid #000;
                    }
                  }

                  
                  
                }
              }
            }
          }
        }
      }
    }
  }
}
