.tpu__earning__section__wrapper {
  .tpu__earning__section__area {
    height: calc(100vh - 104px);
    overflow-y: auto;
    .tpu__earning__section__stats {
      padding: 5px;
      .tpu__earning__stats {
        padding: 5px;
        box-shadow: 0px 0px 6px -3px gray;
        border-radius: 5px;
        .tpu__earing__stats__heading {
          text-align: center;
          .tpu__earning__title {
            h2 {
              font-size: 15px;
              font-family: $font-family;
              font-weight: 600 !important;
              margin-bottom: 5px !important;
            }
          }
          .tpu__earning__total {
            p {
              font-size: 11px;
              font-weight: 600 !important;
              font-family: $font-family;
            }
            span {
              font-size: 12px;
              color: #000;
              font-family: $font-family;
            }
          }
          .tpu__earning__stats__data {
            .tpu__earning__stats__content {
              display: flex;
              align-items: center;
              justify-content: space-between;
              span {
                font-size: 12px;
                font-family: $font-family;
                // font-weight: 600 !important;
              }
            }
          }
        }
      }
    }
    .tpu__earning__tab__area {
      background: #edeffb;
      margin-top: 10px;
      padding: 10px;
      min-height: 100%;
      padding-top: 12px;
      padding-bottom: 30px;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      .tpu__earning__tabs {
        .tpu__earning__tab__content {
          margin-top: 5px;
          // margin-bottom: 80px !important;
          //   TAb history
          .tpu__earning__tabHistory {
            margin-bottom: 8px;
            background: #fff;
            border-radius: 10px;
            padding: 5px;
            .tpu__earning__tabHistory__heading {
              display: flex;
              align-items: center;
              justify-content: space-between;
              gap: 10px;
              cursor: pointer;
              user-select: none;
              .tpu__earning__tabHistory__icon {
                display: flex;
                align-items: center;
                gap: 10px;
                .tpu__earning__tabIcon {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  width: 50px;
                  height: 50px;
                  border-radius: 100px;
                  border: 1px solid lightgray;
                  img {
                    width: 30px;
                    height: 30px;
                  }
                }
                .tpu__earning__tabName {
                  display: flex;
                  flex-direction: column;
                  gap: 2px;
                  h2 {
                    font-size: 13px;
                    font-family: $font-family;
                    font-weight: 600 !important;
                  }
                  span {
                    background: #ec5120;
                    padding: 3px 11px;
                    border-radius: 5px;
                    color: #fff;
                    width: max-content;
                    font-size: 12px;
                    font-family: $font-family;
                    border: 1px solid gray !important;
                  }
                }
              }
              .tpu__earning__tabHistory__amount {
                display: flex;
                justify-content: flex-end;
                align-items: center;
                gap: 10px;
                .tpu__earning__tabPrice {
                  text-align: end;
                  h3 {
                    font-size: 13px;
                    font-family: $font-family;
                    font-weight: 600 !important;
                  }
                  p {
                    color: gray;
                    font-size: 12px;
                    font-family: $font-family;
                    // font-weight: 300 !important;
                  }
                }
                .tpu__icon {
                  span {
                    svg {
                      font-size: 20px;
                      cursor: pointer;
                    }
                  }
                }
              }
            }
            .tpu___earning__tabHistory__details {
              padding: 10px 0;
              // display: none;
              .tpu__earning__tabHistoryDetails__heading {
                text-align: center;
                border-bottom: 1px dashed lightgray;
                padding-bottom: 10px;
                .tpu__earning__tabIcon {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  width: 50px;
                  height: 50px;
                  border-radius: 100px;
                  border: 1px solid lightgray;
                  margin: auto;
                  img {
                    width: 30px;
                    height: 30px;
                  }
                }
                .tpu__earning__details__price {
                  h2 {
                    font-size: 14px;
                    color: #000;
                    font-weight: 600 !important;
                    font-family: $font-family;
                  }
                  p {
                    color: gray;
                    font-size: 14px;
                    font-family: $font-family;
                  }
                }
              }

              .tpu__earning__tabHistory___content {
                .tpu__tabHistory__content {
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  border-bottom: 1px solid lightgray;
                  padding: 8px 5px;
                  font-family: $font-family;
                  font-size: 13px;
                  span{
                    color: gray;
                  }
                  strong{
                    font-weight: 600 !important;
                    text-align: end;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
