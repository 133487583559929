.tpu__dashboard__header__wrapper {
  padding: 13px 5px;
  background: #fff;
  position: sticky;
  top: 0;
  right: 0;
  left: 0;
  width: 100%;
  z-index: 99;
  .tpu__dashboard__header__widgets {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .tpu__dashboard__header__element {
      p {
        font-size: 15px;
        font-family: $font-family;
        font-weight: 600 !important;
      }
      span {
        svg {
          font-size: 25px;
          cursor: pointer;
          user-select: none;
        }
      }
      .tpu__dashboard__header__widgets {
        display: flex;
        align-items: center;
        gap: 5px;
        .tpu__dashboard__header__icon {
          cursor: pointer;
          user-select: none;
          box-shadow: 0 2px 3.8px rgba(0, 0, 0, 0.25);
          border-radius: 50px;
          .claimImg {
            user-select: none;
            display: flex;
            align-items: center;
            border: 1px solid lightgray;
            padding: 4px 7px;
            border-radius: 50px;
            gap: 5px;
            cursor: pointer;
            img {
              width: 16px;
              height: 16px;
            }
            .Header__points {
              font-size: 11px;
              font-family: $font-family !important;
              font-weight: 600 !important;
              color: #ff0000;
            }
          }
          .header__profilePic {
            width: 28px;
            height: 28px;
            border-radius: 50px;
            object-fit: cover;
            border: none !important;
          }
          @mixin dropdown {
            position: absolute;
            background: #fff;
            // box-shadow: $box-shadow;
            z-index: 99999;
            border: 1px solid lightgray;
            border-radius: 5px;
            transition: all 0.3s ease-in-out;
            margin-top: 5px;
          }
          // Header Dropdown css
          .clickable_submenu {
            @include dropdown();
            width: 180px;
            height: auto;
            right: 5px;

            .submenu {
              list-style: none;

              .header {
                border-bottom: 1px solid lightgray;
                display: flex;
                align-items: center;
                padding: 0px 10px;

                .img {
                  margin-right: 10px;

                  img {
                    width: 30px;
                    height: 30px;
                    border-radius: 50px;
                  }
                }

                .name {
                  h4 {
                    color: #000;
                    padding-top: 10px;
                    text-transform: capitalize;
                    font-size: 14px;
                    width: 115px;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                  }

                  p {
                    color: #000;
                    font-size: 13px;
                    // text-align: center;
                    padding-bottom: 0px;
                    text-transform: capitalize;
                  }
                }
              }

              .submenu_list {
                border-bottom: 1px solid lightgray;
                // &:hover {
                //   background-color: $bg-sidebar-hover;
                // }
                .act-btn {
                  padding: 3px 6px;
                  color: #fff;
                  font-size: 10px;
                  border: none;
                  border-radius: 6px;
                  margin-right: 8px;
                }

                .activate {
                  background-color: #39bd57;
                }

                .deactivate {
                  background-color: #676262;
                }

                &:last-child {
                  border: none;
                }

                .submenu_link {
                  color: #000;
                  display: flex;
                  align-items: center;
                  padding: 5px 10px;
                  text-transform: capitalize;
                  font-size: 13px;
                  transition: all 0.3s ease-in-out;
                  font-weight: 500;
                  // padding: 10px;
                  align-items: center;
                  cursor: pointer;
                  font-family: $font-family;

                  // &:hover {
                  //   color: $text-primary;
                  // }
                }
                .sponsor {
                  color: #000;
                  text-transform: capitaliz;
                  font-size: 10px;
                  transition: all 0.3s ease-in-out;
                  font-weight: 500;
                  cursor: pointer;
                  padding-left: 30px;
                  padding-bottom: 10px;
                }
              }
            }
          }
        }
        .tpu__dashboard__header__icon2 {
          cursor: pointer;
          user-select: none;
          box-shadow: 0 2px 3.8px rgba(0, 0, 0, 0.25);
          border-radius: 50px;
          display: flex;
          align-items: center;
          justify-content: center;

          .claimImg {
            user-select: none;
            display: flex;
            align-items: center;
            // border: 1px solid lightgray;
            padding: 4px 7px;
            border-radius: 50px;
            gap: 5px;
            cursor: pointer;
            img {
              width: 16px;
              height: 16px;
            }
            .Header__points {
              font-size: 11px;
              font-family: $font-family !important;
              font-weight: 600 !important;
              color: #ff0000;
            }
          }
          .header__profilePic {
            width: 28px;
            height: 28px;
            border-radius: 50px;
            object-fit: cover;
            border: none !important;
          }
          @mixin dropdown {
            position: absolute;
            background: #fff;
            // box-shadow: $box-shadow;
            z-index: 99999;
            border: 1px solid lightgray;
            border-radius: 5px;
            transition: all 0.3s ease-in-out;
            margin-top: 150px;
          }
          // Header Dropdown css
          .clickable_submenu {
            @include dropdown();
            width: 180px;
            height: auto;
            right: 5px;

            .submenu {
              list-style: none;

              .header {
                border-bottom: 1px solid lightgray;
                display: flex;
                align-items: center;
                padding: 0px 10px;

                .img {
                  margin-right: 10px;

                  img {
                    width: 30px;
                    height: 30px;
                    border-radius: 50px;
                  }
                }

                .name {
                  h4 {
                    color: #000;
                    padding-top: 10px;
                    text-transform: capitalize;
                    font-size: 14px;
                    width: 115px;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                  }

                  p {
                    color: #000;
                    font-size: 13px;
                    // text-align: center;
                    padding-bottom: 0px;
                    text-transform: capitalize;
                  }
                }
              }

              .submenu_list {
                border-bottom: 1px solid lightgray;
                // &:hover {
                //   background-color: $bg-sidebar-hover;
                // }
                .act-btn {
                  padding: 3px 6px;
                  color: #fff;
                  font-size: 10px;
                  border: none;
                  border-radius: 6px;
                  margin-right: 8px;
                }

                .activate {
                  background-color: #39bd57;
                }

                .deactivate {
                  background-color: #676262;
                }

                &:last-child {
                  border: none;
                }

                .submenu_link {
                  color: #000;
                  display: flex;
                  align-items: center;
                  padding: 5px 10px;
                  text-transform: capitalize;
                  font-size: 13px;
                  transition: all 0.3s ease-in-out;
                  font-weight: 500;
                  // padding: 10px;
                  align-items: center;
                  cursor: pointer;
                  font-family: $font-family;

                  // &:hover {
                  //   color: $text-primary;
                  // }
                }
                .sponsor {
                  color: #000;
                  text-transform: capitaliz;
                  font-size: 10px;
                  transition: all 0.3s ease-in-out;
                  font-weight: 500;
                  cursor: pointer;
                  padding-left: 30px;
                  padding-bottom: 10px;
                }
              }
            }
          }
        }
      }
    }
  }
}
