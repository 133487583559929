:root {
  --primary-bg-color: #e6b325;
  --white-bg-color: #FFF;
  --primary-text-color: #000;
  --font-family: "Lato", sans-serif;
}
.dark {
  --white-bg-color: #FFF;
  --primary-bg-color: #e6b325;
  --primary-text-color: #000;
  --font-family: "Lato", sans-serif;
}

$primary-bg-color: var(--primary-bg-color);
$primary-text-color: var(--primary-text-color);
$white-bg-color: var(--white-bg-color);


$font-family: var(--font-family);
