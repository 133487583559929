.tpu__authSplashScreen__wrapper {
  background: #0b59f9;
  position: relative;

  .tpu__splashScreen__bottomSheet__content {
    padding-top: 30px;
    .google__login__btn {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      gap: 20px;
      .google__btn {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;
        width: 100%;
        background: #fff !important;
        border: 1px solid #0b59f9 !important;
        box-shadow: none !important;
        outline: none;
        padding: 8px 10px;
        border-radius: 5px !important;
        div{
          padding: 0px !important;
        }
        img {
          width: 20px;
          height: 20px;
        }
        span {
          color: #575757;
          font-weight: 700 !important;
          font-family: $font-family;
          font-size: 13px;
          text-transform: uppercase;
        }
      }
      .other__login {
        background: transparent !important;
        color: #575757;
        font-size: 13px;
        font-weight: 700 !important;
        border: none;
        outline: none;
        font-family: $font-family;
      }
    }
    .tpu__separator {
      position: relative;
      width: 100%;
      margin: 35px 0px;
      .dashed__separator {
        position: relative;
        background: #fff;
        width: 35px;
        text-align: center;
        color: gray;
        font-size: 13px;
        margin: auto !important;
        margin-bottom: -9px !important;
      }
      .separator__text {
        border-bottom: 1px dashed gray;
        font-size: 0px;
      }
    }
    .otherLoginButtons {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: row;
    }
  }
}
