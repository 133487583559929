.tpu__splashScreen__wrapper {
  background: $primary-bg-color;
  position: relative;
  .tpu__splashScreen__slider {
    .tpu__splashScreen__content {
      padding: 10px;
      .tpu__splashScreen__img {
        width: 100%;
        text-align: center;
        img {
          width: 100%;
          margin: auto;
        }
      }
      .tpu__splashScreen__title {
        h2 {
          font-size: 20px;
          color: $primary-text-color;
          font-weight: 600 !important;
          margin-bottom: 10px;
          text-align: center;
          padding: 20px 0;
          font-family: $font-family !important;
        }
      }
      .tpu__splashScreen__para {
        p {
          font-size: 12px;
          text-align: center;
          color: $primary-text-color;
          font-weight: 400 !important;
          margin-bottom: 20px;
        }
      }
    }
  }

  .tpu__splashScreen__bottomSheet__content {
    .section__title {
      h2 {
        font-size: 17px;
        font-weight: 700 !important;
        font-family: $font-family !important;
        margin-bottom: 10px !important;
      }
    }
    .section__para {
      padding-bottom: 30px;
      p {
        font-size: 13px;
        color: $primary-text-color;
        font-family: $font-family !important;
      }
    }
    .google__login__btn {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      gap: 20px;
      .google__btn {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 5px;
        width: 90%;
        background: #e5f5eb !important;
        border: 1px solid #d4e9e0 !important;
        box-shadow: none !important;
        outline: none;
        padding: 5px 10px;
        border-radius: 5px !important;
        div{
          padding: 0px !important;
        }
        img {
          width: 20px;
          height: 20px;
        }
        span {
          color: #575757;
          font-weight: 600 !important;
          font-family: $font-family;
          font-size: 13px;
          text-transform: capitalize;
        }
      }
      .other__login {
        background: transparent !important;
        color: #575757;
        font-size: 13px;
        font-weight: 700 !important;
        border: none;
        outline: none;
        font-family: $font-family;
      }
    }
  }
}
