.custom_modal {
  position: fixed;
  background: #fff;
  border: 1px solid hsla(0deg, 0%, 88%, 0.38) !important;
  border-radius: 10px;
  padding: 15px;
  width: 85% !important;
  height: max-content;
  max-height: 90vh;
  overflow: auto;
  margin: 0 auto;
  top: 50%;
  left: 0;
  right: 0;
  transform: translateY(-50%);
  transform-origin: center;
  z-index: 999999999999;

  @include breakpoints(largeLaptop) {
    width: 50%;
  }

  @include breakpoints(tablet) {
    width: 60%;
  }

  @include breakpoints(extralargeMobile) {
    width: 100%;
    height: max-content;
    top: 20%;
    bottom: 20px;
    transform: translateY(0);
    border-radius: 10px;
    // padding-bottom: 80px;
    min-height: 50% !important;
  }

  .title {
    display: flex;
    align-items: center;
    justify-content: center;

    h2 {
      font-size: 18px;
      font-weight: 700 !important;
      color: "#000";

      @include breakpoints(semiPortraitTablet) {
        font-size: 18px;
      }
    }

    span {
      cursor: pointer;
      user-select: none;
      color: #fff;
      font-weight: 900 !important;
      background-color: #000;
      border-radius: 50%;
      position: absolute;
      top: 10px;
      right: 10px;
      z-index: 1000;
      height: 25px;
      width: 25px;
      display: flex;
      justify-content: center;
      align-items: center;

      .closeIcon {
        width: 20px;
        height: 20px;
      }
    }
  }
}

// backdrop effect
.backdrop_effect {
  position: fixed;
  background: rgb(0 0 0 / 71%);
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
  cursor: pointer;
}
