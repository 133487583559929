.manage-payment-wrapper {
  background: white;
  .manage-payment-section-area {
    height: calc(100vh - 108px);
    overflow-y: auto;

    .manage-bank-header-section {
      display: flex;
      align-items: center;
      background-color: #007bff;
      margin: 10px;
      padding: 10px;
      border-radius: 18px;
      color: white;

      .card-icon-container {
        font-size: 40px;
        padding: 15px 15px;
        background-color: #fff;
        color: #000000;
        display: flex;
        justify-items: center;
        align-items: center;
        margin-top: 7px;
        margin-bottom: 7px;
        margin-left: 2px;
        margin-right: 15px;
        text-align: center;
        border-radius: 10px;

        .card-icon {
          text-align: center;
        }
      }

      .manage-bank-header-content {
      }
    }

    .bank-accounts-section {
      background-color: rgba(233, 233, 233, 0.711);
      margin-top: 15px;
      padding: 15px;
      border-bottom: 2px solid rgba(154, 62, 170, 0.867);

      .bank-accounts-text {
        margin-bottom: 25px;
        font-weight: bold !important;
        text-align: center;
      }
    }

    .add-bank-section {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      margin-top: 10px;
      margin-right: 5px;
      margin-bottom: 20px;

      .add-bank-button {
        background-color: #ff6600;
        color: white;
        padding: 10px 10px;
        margin-top: 10px;
        border: none;
        border-radius: 18px;
        cursor: pointer;
      }
      .add-bank-button:hover {
        background-color: #e65c00;
      }
    }

    .bank-account-card {
      margin: 10px;
      border: 1px solid #ccc;
      border-radius: 10px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

      .account-header {
        margin: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      .account-header span {
        font-weight: 800;
      }
      .bankName {
        font-size: large;
        font-weight: bold !important;
      }

      .bank-tag {
        background-color: #007bff;
        color: white;
        padding: 2px 5px;
        border-radius: 5px;
      }

      .account-details {
        margin: 10px;
        margin-bottom: 0;
        display: flex;
        font-size: 14px;

        justify-content: space-between;
      }
      .holder-name {
        margin: 10px;
        font-size: 17px;
      }

      .account-actions {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 5px 10px 5px 10px;
        border-top: 1px solid #ccc;

        .edit-icon {
          cursor: pointer;
          color: blue;
          font-size: 23px;
        }

        .remove-btn {
          background: none;
          border: none;
          color: #dc3545;
          cursor: pointer;
          display: flex;
          font-size: 17px;
          font-weight: 900;
          align-items: center;
          color: #db1f1f;
        }
      }
    }

    .disclaimer-section {
      justify-content: center;
      margin-top: 20px;
      padding: 15px;
      border-radius: 10px;
    }
    .disclaimer-section ol {
      margin: 10px 0 0;
      padding-top: 5px;
      padding-left: 24px;
      background-color: #e3ffe5;
      border-radius: 10px;
    }

    .disclaimer-section li {
      padding: 5px;
      margin-bottom: 5px;
      font-size: 14px;
    }

    .disclaimer-section h4 {
      margin: 0;
      margin-left: 5px !important;
      color: #890086;
      font-size: 16px;
      font-weight: bold !important;
    }

    .disclaimer-icon {
      display: flex;
      align-items: center;
      color: #e65c00;
    }
  }

  .disclaimer-li {
    border-bottom: 2px solid rgba(0, 0, 0, 0.093);
    width: 90%;
  }

  .disclaimer-li-last {
  }
  .manage-payment-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: flex-end; // Changed from center to flex-end
    z-index: 9999;
  }

  .manage-payment-modal-content {
    background: white;
    padding: 20px;
    border-radius: 10px 10px 0 0; // Rounded corners only at the top
    width: 100%;
    max-width: 100%; 
    position: relative;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.2);
    animation: slide-up 0.3s ease-out;

    h3 {
      margin-top: 0;
      margin-bottom: 40px !important;
      font-size: 18px;
      text-align: center;

    }

    form {
      display: flex;
      flex-direction: column;
      gap: 15px;

      input {
        width: 100%;
        padding: 12px;
        border: 1px solid #ccc;
        border-radius: 5px;
        font-size: 14px;
      }

      button.submit-btn {
        background-color: #4caf50;
        color: white;
        border: none;
        padding: 12px;
        border-radius: 5px;
        cursor: pointer;
        font-size: 16px;
        margin-top: 10px;
      }
    }

    .close-btn {
      position: absolute;
      top: 10px;
      right: 10px;
      background: none;
      border: none;
      font-size: 24px;
      cursor: pointer;
      color: #888;
    }
  }

  @keyframes slide-up {
    from {
      transform: translateY(100%);
    }
    to {
      transform: translateY(0);
    }
  }

  // Media query for larger screens
  @media (min-width: 768px) {
    .manage-payment-modal-content {
      max-width: 400px;
      border-radius: 10px;
      margin-bottom: 20px;
    }
  }

  //

  .modal-overlay {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: flex-end;
    z-index: 9999;
  }

  .modal-content {
    background: white;
    padding: 30px;
    text-align: center;
    border-radius: 10px 10px 0 0;
    width: 100%;
    max-width: 350px;
    position: relative;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.2);
    animation: slide-up 0.3s ease-out;
    z-index: 10000;
  }

  .modal-content h3 {
    margin-top: 20px;
    padding: 10px;
  }

  @keyframes slide-up {
    from {
      transform: translateY(100%);
    }
    to {
      transform: translateY(0);
    }
  }

  .modal-content h3 {
    margin-top: 0;
  }

  .modal-content form input {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }

  .close-btn {
    position: absolute;
    top: -45px;
    right: -25px;
    background: #fff;
    color: #000;
    border-radius: 50%;
    border: 2px solid #ccc;
    padding: 5px;
    cursor: pointer;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    font-size: 19px;
    font-weight: bold;
  }

  .submit-btn {
    width: 100%;
    background-color: #28a745;
    color: white;
    padding: 10px;
    border: none;
    border-radius: 20px;
    cursor: pointer;
  }

  .submit-btn:hover {
    background-color: #218838;
  }

  .update-btn {
    width: 100%;
    background-color: #007bff;
    color: white;
    padding: 10px;
    border: none;
    border-radius: 20px;
    cursor: pointer;
  }

  .update-btn:hover {
    background-color: #0069d9;
  }

  .delete-info {
    background: linear-gradient(to right, #0033cc, #66ccff);
    color: white;
    border-radius: 10px;
    padding: 20px;
    margin-bottom: 20px;
    text-align: left;
    display: flex;
    justify-content: space-between;
  }

  .delete-info p {
    font-size: 18px;
    margin: 10px 0;
    padding: 0px 10px;
  }

  .delete-info p strong {
    color: #fff;
    font-weight: 700;
  }
  .modal-actions {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .delete-info {
    display: flex;
    justify-content: space-between;
  }
  .confirm-delete,
  .cancel-btn {
    padding: 5px 5px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    width: 40%;
    font-size: 16px;
    gap: 10px;
  }

  .confirm-delete {
    background-color: #db1f1f;
    color: white;
    width: fit-content;
  }

  .cancel-btn {
    background-color: #009ffd;
    color: white;
    /* width: fit-content; */
  }

  .confirm-delete:hover {
    background-color: #c82333;
  }

  .cancel-btn:hover {
    background-color: #5a6268;
  }

  ::-webkit-scrollbar {
    display: none; /* Hide scrollbar */
  }
}
