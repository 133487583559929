@import "../abstract/variables";

.tp_sectiontable_wrapper {
  margin-top: 20px;

  @include breakpoints(tablet) {
    margin-top: 10px;
  }

  @include breakpoints(extralargeMobile) {
    margin-top: 5px;
  }

  .tp_sectiontable_card {
    padding: 25px;
    min-height: 280px;

    @include breakpoints(tablet) {
      padding: 5px;
    }

    .tp_sectiontable_title {
      margin-bottom: 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      @include breakpoints(tablet) {
        display: flex;
        // margin-bottom: 5px;
        align-items: center;
      }

      h2 {
        font-size: 15px;
        text-align: left;
        color: #000;
        text-transform: uppercase;
        margin-bottom: 10px !important;
        position: relative;
        margin-left: 9px !important;
        font-weight: 500 !important;
        @include breakpoints(extralargeMobile) {
          font-size: 13px !important;
        }

        &::before {
          content: "";
          position: absolute;
          width: 3px;
          height: 90%;
          background: #000;
          top: 1px;
          left: -8px;
          bottom: 0;
        }

        @include breakpoints(tablet) {
          margin-bottom: 5px !important;
        }
      }

      .tp_sectiontable_balance {
        h2 {
          font-size: 14px;
          color: #000;
          background-color: #fff;
          border-radius: 50px;
          padding: 5px 10px;

          @include breakpoints(tablet) {
            margin-top: 10px !important;
          }
        }
      }

      .tp_sectiontable_right_side {
        display: flex;
        justify-content: space-between;
      }
    }

    .tp_sectiontable_table {
      .common_table {
        width: 100%;
      }
    }

    .tp_sectiontable_calculate {
      display: flex;
      align-items: center;
      justify-content: space-between;

      @include breakpoints(tablet) {
        display: inherit;
      }

      h2 {
        font-size: 20px;
        font-weight: 600;
        padding: 10px 0;
        text-align: center;
        border-radius: 50px;
        color: #fff;
        width: 40%;
        margin: auto;

        @include breakpoints(tablet) {
          width: 100%;
          font-size: 16px;
        }
      }
    }

    .search_by_button {
      margin-top: -20px;
      margin-bottom: 10px;

      @include breakpoints(extralargeMobile) {
        padding-top: 5px;
        margin-bottom: 10px;
        white-space: nowrap;
        overflow-x: auto;
        flex-wrap: nowrap !important;
      }

      .btn {
        padding: 4px 8px;
        color: #fff;
        font-size: 10px;
        border: none;
        border-radius: 6px;
        margin-right: 10px;
        position: relative;
        display: inline-block;
        @include breakpoints(extralargeMobile) {
          margin-right: 0px !important;
        }
      }
    }

    .select_wrapper {
      margin-bottom: 15px;
      label {
        width: 100%;
        font-weight: 600;
        font-size: 13px;
        color: #000;
        transition: all 0.3s ease-in-out;
        @include breakpoints(tablet) {
          font-size: 14px;
        }
      }
  
    }
  }
}

.ss-trade_sectiontable_wrapper {
  // margin-top: 20px;
  .ss-trade_sectiontable_card {
    padding: 10px;
    min-height: 250px;
    .ss-trade_sectiontable_title {
      margin-bottom: 20px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
      @include breakpoints(tablet) {
        display: inherit;
      }
      .ss-trade_sectiontable_title_container {
        @include breakpoints(tablet) {
          margin-bottom: 10px;
        }
        h2 {
          font-size: 15px;
          text-align: left;
          color: #000;
          text-transform: uppercase;
          margin-bottom: 10px !important;
          position: relative;
          margin-left: 9px !important;
          font-weight: 500 !important;
          &::before {
            content: "";
            position: absolute;
            width: 3px;
            height: 87%;
            background: #000;
            top: 1px;
            left: -8px;
            bottom: 0;
          }
        }
        :disabled {
          background: #9b9696 !important;
          cursor: no-drop !important;
        }
      }
      .left {
        display: flex;
        align-items: center;
        @include breakpoints(largeMiniMobile) {
          flex-direction: column !important;
        }
        .searchbar_input {
          .spacial_search_input {
            padding: 10px;
            border-radius: 5px;
            outline: none;
            color: #000;
            background: #fff;
            font-size: 14px;
            border: none;
            border: 1px solid lightgray;
            min-width: 200px;
          }
        }
      }
    }
    .ss-trade_sectiontable_table {
      .common_table {
        width: 100%;
      }
    }
    .ss-trade_sectiontable_calculate {
      display: flex;
      align-items: center;
      justify-content: space-between;
      @include breakpoints(tablet) {
        display: inherit;
      }
      h2 {
        font-size: 20px;
        font-weight: 600;
        padding: 10px 0;
        text-align: center;
        border-radius: 50px;
        color: #fff;
        width: 40%;
        margin: auto;
        @include breakpoints(tablet) {
          width: 100%;
          font-size: 16px;
        }
      }
    }
  }
}
