.headerDropdown-container {
  position: absolute;
  right: 0;
  width: 200px;
  min-width: max-content;
  height: auto;
  background-color: #fff;
  border: 1px solid #eee;
  border-radius: 8px;
  box-shadow: 0 7px 9.11px rgba(115, 119, 123, 0.41);
  transition: top 0.3s ease;
  z-index: 1000;
  overflow: hidden;
}

.headerDropdown-inner-container {
  padding: 5px 15px;
  width: 100%;
}

.headerDropdown-item {
  width: 100%;
  border-bottom: 2px solid #eee !important;
  padding: 8px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: none;
  border: none;
  cursor: pointer;
  color: #090909;
  transition: background-color 0.2s;
}

.headerDropdown-points-badge {
  display: flex;
  align-items: center;
  background-color: #3871ff;
  padding: 3px 6px;
  border-radius: 5px;
  gap: 5px;
}



.headerDropdown-points-badge span {
  color: #fff;
  font-size: 12px;
}

.membership-badge {
  padding: 3px 5px !important;
  border-radius: 5px !important;
}

.headerDropdown-points-icon {
  width: 15px;
  height: 15px;
}

.headerDropdown-item:last-child {
  border-bottom: none !important;
}

.headerDropdown-item:hover {
  background-color: #f5f5f5;
}

.headerDropdown-item span {
  display: flex;
  align-items: center;
  gap: 8px;
}
