@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600&display=swap');

:root {
  --primary-color: #3a0ca3;
  --secondary-color: #4361ee;
  --accent-color: #4cc9f0;
  --text-color: #2b2d42;
}

.privacy-policy {
  font-family: 'Poppins', sans-serif;
  line-height: 1.6;
  color: var(--text-color);
  background-color: var(--background-color);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* padding: 0rem 0; */
}

.container {
  max-width: 450px;
  margin: 0 auto;
  /* padding: 0 1rem; */
}

.privacy-policy__content {
  background-color: #ffffff;
  border-radius: 20px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  padding: 2rem;
  position: relative;
  overflow: hidden;
}

.privacy-policy__content::before {
  content: '';
  position: absolute;
  top: -50px;
  left: -50px;
  width: 100px;
  height: 100px;
  background-color: var(--accent-color);
  border-radius: 50%;
  opacity: 0.1;
}

.privacy-policy__content::after {
  content: '';
  position: absolute;
  bottom: -50px;
  right: -50px;
  width: 100px;
  height: 100px;
  background-color: var(--secondary-color);
  border-radius: 50%;
  opacity: 0.1;
}

.privacy-policy__header {
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
}

.privacy-policy__icon {
  font-size: 2.5rem;
  color: var(--primary-color);
  margin-right: 1rem;
}

.privacy-policy__title {
  font-size: 2rem;
  color: var(--primary-color);
  font-weight: 600;
  margin: 0;
}

.privacy-policy__section {
  margin-bottom: 2rem;
  position: relative;
  z-index: 1;
}

.privacy-policy__section h2 {
  font-size: 1.5rem;
  color: var(--secondary-color);
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
}

.section-icon {
  margin-right: 0.5rem;
  color: var(--accent-color);
}

.privacy-policy__section p {
  margin-bottom: 1rem;
  font-weight: 300;
}

.privacy-policy__list {
  list-style-type: none;
  padding-left: 1rem;
}

.privacy-policy__list li {
  margin-bottom: 0.5rem;
  position: relative;
  padding-left: 1.5rem;
}

.privacy-policy__list li::before {
  content: '•';
  color: var(--accent-color);
  position: absolute;
  left: 0;
  font-size: 1.2rem;
}

a {
  color: var(--secondary-color);
  text-decoration: none;
  transition: color 0.3s ease;
}

a:hover {
  color: var(--primary-color);
  text-decoration: underline;
}

@media (max-width: 768px) {
  .privacy-policy__title {
    font-size: 1.8rem;
  }

  .privacy-policy__section h2 {
    font-size: 1.3rem;
  }

  .privacy-policy__content {
    padding: 1.5rem;
  }
}

/* Animations */
@keyframes fadeIn {
  from { opacity: 0; transform: translateY(20px); }
  to { opacity: 1; transform: translateY(0); }
}

.privacy-policy__section {
  animation: fadeIn 0.5s ease-out forwards;
  opacity: 0;
}

.privacy-policy__section:nth-child(1) { animation-delay: 0.1s; }
.privacy-policy__section:nth-child(2) { animation-delay: 0.2s; }
.privacy-policy__section:nth-child(3) { animation-delay: 0.3s; }
.privacy-policy__section:nth-child(4) { animation-delay: 0.4s; }
.privacy-policy__section:nth-child(5) { animation-delay: 0.5s; }
.privacy-policy__section:nth-child(6) { animation-delay: 0.6s; }