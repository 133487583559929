.tpu__premium__wrapper {
  .tpu__premium__section__area {
    height: calc(100vh - 108px);
    overflow-y: auto;
    .tpu__premium__section__cards {
      .tpu__premium__card {
        margin: 10px;
        padding: 15px;
        border-radius: 5px;
        background: #efefef;
        .tpu__card__heading {
          text-align: center;
          h2 {
            font-size: 20px;
            font-weight: 600 !important;
            font-family: $font-family;
            color: #0b57d0;
          }
          h4 {
            font-size: 20px;
            color: #000;
            font-weight: 600 !important;
            font-family: $font-family;
            margin: 5px 0;
          }
          p {
            font-size: 14px;
            color: #000;
            font-weight: 600 !important;
            font-family: $font-family;
          }
        }
        .tpu__premium__section__contents {
          margin-top: 10px;
          .tpu__premium__content__group {
            .tpu__premium__group__title {
              color: #0b57d0;
              font-size: 13px;
              font-weight: 600 !important;
              margin-bottom: 2px;
              font-family: $font-family;
            }
            .tpu__premium__group__text {
              font-size: 12px;
              margin-bottom: 2px;
              font-family: $font-family;
              svg {
                margin-bottom: -3px;
                margin-right: 5px;
              }
              strong {
                font-weight: 700 !important;
              }
              a {
                color: #0b57d0 !important;
                font-weight: 600 !important;
              }
            }
            button {
              margin-top: 15px;
              color: #fff;
              background: #0b57d0;
              width: 100%;
              padding: 5px;
              outline: none;
              border: none;
              cursor: pointer;
              border-radius: 5px;
              font-size: 13px;
              text-transform: capitalize;
              font-family: $font-family;
            }
          }
        }
      }
    }
    .container {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      min-height: 80vh;

      &.success {
        background-color: #f8fff8;
      }

      &.failure {
        background-color: #fff8f8;
      }
    }
    .status-card {
      background: white;
      padding: 2rem;
      border-radius: 10px;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
      text-align: center;
      max-width: 400px;
      width: 90%;

      h1 {
        margin: 20px !important;
        color: #333;
      }

      p {
        color: #666;
        margin-bottom: 20px !important;
      }
    }

    .status-icon {
      width: 80px;
      height: 80px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 40px;
      margin: 0 auto;

      &.success-icon {
        background-color: #4caf50;
        color: white;
      }

      &.failure-icon {
        background-color: #f44336;
        color: white;
      }
    }

    .back-btn {
      background: #007bff;
      color: white;
      border: none;
      padding: 12px 30px;
      border-radius: 5px;
      cursor: pointer;
      font-size: 16px;
      transition: background 0.3s ease;

      &:hover {
        background: #0056b3;
      }
    }
  }
}
