@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@500;600&display=swap");
.tpu__taskDetails__wrapper {
  .tab-container-wrapper {
    position: relative;
    overflow-x: hidden;
    
    &::after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      height: 100%;
      width: 24px;
      background: linear-gradient(to right, transparent, #fff);
      pointer-events: none;
    }
  }

  .tab-container {
    display: flex;
    padding: 15px 10px;
    gap: 10px;
    overflow-x: auto;
    scrollbar-width: none; 
    -ms-overflow-style: none;
    white-space: nowrap;
    
    &::-webkit-scrollbar {
      display: none; 
    }
  }

  .tab-button {
    padding: 8px 16px;
    border-radius: 50px;
    background-color: #F5F5F5;
    border: none;
    cursor: pointer;
    transition: all 0.2s ease;

    &:focus {
      outline: none;
    }

    &.active-tab {
      background-color: #C3E7FF;
    }
  }

  .tab-text {
    font-size: 12px;
    color: #666;
    font-weight: 500;
    text-wrap: nowrap !important;

    &.active-tab-text {
      color: #044973;
      font-weight: 600;
    }
  }

  @media screen and (max-width: 768px) {
    .tab-text {
      font-size: 12px;
    }
    
    .tab-button {
      padding: 8px 12px;
    }
  }

  .tpu__taskDetails__section__area {
    // height: calc(100vh - 108px);
    overflow-y: auto;
    padding: 10px;
    scrollbar-width: none;
    .tpu__taskDetails__cards {
      margin-bottom: 10px;
      .tpu__taskDetails__card {
        .tpu__taskDetails__cardData {
          border-radius: 10px;
          padding: 10px;
          text-align: center;
          .tpu__taskDetails__cardIcon {
            display: flex;
            align-items: center;
            gap: 10px;
            img {
              width: 40px;
              height: 40px;
              background: #fff;
              padding: 5px;
              border-radius: 5px;
            }
            p {
              color: #fff;
              font-weight: 500 !important;
              font-size: 14px;
            }
          }
          .pointButton {
            text-align: center;
            background: #fff;
            padding: 5px 8px;
            border-radius: 5px;
            font-family: "Roboto", sans-serif;
            font-size: 13px;
          }
        }
        .tpu__taskDetails__info {
          padding: 10px;
          border-radius: 10px;
          .tpu__taskDetails__cardTabs {
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-bottom: 2px solid gray;
            padding-bottom: 5px;
            margin-bottom: 5px;
            span {
              color: #000;
              font-size: 15px;
              font-family: $font-family;
              cursor: pointer;
              user-select: none;
            }
          }
          .tpu__taskDetails__info__data {
            .tpu__taskDetails__details__part {
              .tpu__taskDetails__group {
                margin-bottom: 10px;
                h3 {
                  font-size: 15px;
                  font-family: $font-family;
                  font-weight: 600 !important;
                }
                p {
                  font-size: 13px;
                  font-family: $font-family;
                  font-weight: 500 !important;
                }
                button {
                  padding: 5px 8px;
                  color: #fff;
                  background: #ec5120;
                  outline: none;
                  border: 1px solid lightgray;
                  border-radius: 5px;
                }
                .point__btn {
                  background: #2f68fd;
                  padding: 5px 15px;
                }
              }
              .button__group {
                display: flex;
                align-items: center;
                gap: 15px;
              }
            }
            .tpu__taskDetails__earnings__part {
              ol {
                padding: 15px;
                li {
                  font-family: $font-family;
                  font-size: 15px;
                  padding: 5px 0px;
                }
              }
            }
            // file Upload
            .tpu__taskDetails__fileUpload {
              display: flex;
              align-items: center;
              justify-content: space-between;
              gap: 5px;
              input {
                border: 1px solid lightgray;
                padding: 2px;
                border-radius: 5px;
              }
              .submitButton {
                padding: 5px 8px;
                color: #fff;
                background: #ec5120;
                outline: none;
                border: 1px solid lightgray;
                border-radius: 5px;
              }
            }
          }
        }
      }
    }
    
    // Attachment Modal
    .attachment-modal-overlay {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(72, 72, 72, 0.144); // Semi-transparent dark background
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 1000;
    }
    
    .attachment-modal {
      background-color: white;
      border-radius: 4px;
      width: 80%;
      max-width: 350px;
      position: relative;

      h2 {
        color: black;
        font-size: 20px;
        margin: 10px !important;
        margin-bottom: 0px !important;
        text-align: center;
        font-weight: bold !important;
      }

      @media screen and (max-width: 768px) {
        max-width: 425px;
        
        h2 {
          font-size: 22px;
        }
      }
    }
    
    .image-container {
      border-radius: 4px;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
      
      
      .screenshot-image {
        margin-top: 15px !important;
        margin-bottom: 15px !important;
        width: 60%;
        height: auto;
        display: block;

        @media screen and (max-width: 768px) {
          margin-top: 20px !important;
          margin-bottom: 20px !important;
          width: 69%;
        }
      }
    }
    
    .ok-button-container {
      display: flex;
      justify-content: center;
      margin-bottom: 10px;
    }
    
    .ok-button {
      background-color: #000;
      color: white;
      border: none;
      border-radius: 4px;
      padding: 8px 24px;
      font-size: 16px;
      cursor: pointer;
      min-width: 100px;
      
      &:hover {
        opacity: 0.9;
      }
    }

    //
    .history-container {
      padding-bottom: 60px;
      padding: 8px;
      padding-top: 0px;
    }

    .heading {
      font-size: 20px;
      text-align: center;
      margin-bottom: 10px;
      font-weight: bold;
      color: #000;
    }

    .card {
      margin-bottom: 10px;
    }

    .card-gradient {
      border-radius: 15px;
      padding: 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .card-id {
      background-color: #2f68fd;
      border-radius: 5px;
      padding: 1px 7px;
      position: absolute;
      top: -10px;
      right: 10px;
      color: #fff;
      font-size: 12px;
      text-transform: uppercase;
    }

    .card-content {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    .card-text {
      margin-left: 10px;
    }

    .card-details {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-top: 5px;
    }

    .response-message {
      border-radius: 3px;
      border: 1px solid #000;
      background-color: #fff;
      margin-left: 10px;
      padding: 0 5px;
    }

    .card-footer {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }

    .card-point {
      font-size: 16px;
      font-weight: 600;
      width: 100px;
    }

    .card-date {
      font-size: 13px;
      color: gray;
    }

    .card-status {
      margin-top: 5px;
      display: flex;
      justify-content: flex-end;
    }

    .status {
      background-color: #1daf33;
      color: #fff;
      padding: 0 5px;
      border-radius: 4px;
      font-size: 12px;
      text-transform: capitalize;
    }

    .status.rejected {
      background-color: #cb434d;
    }

    .status.pending {
      background-color: orange;
    }

    .page-controls {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .page-btn {
      background-color: #2f68fd;
      color: #fff;
      padding: 8px;
      border-radius: 5px;
      margin: 0 5px;
    }

    .page-btn[disabled] {
      background-color: gray;
    }

    .current-page {
      padding: 4px 20px;
      background-color: #fff;
      border-radius: 5px;
    }

    .no-data {
      font-family: "Roboto", sans-serif;
      color: #626262;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 35px 0;
    }

    .no-data-image {
      width: 70px;
      height: 70px;
      margin-bottom: 10px;
    }
  }

  .smt-history-card {
    border-radius: 6px;
    min-height: 55px;
    margin-bottom: 8px;
    font-family: "Roboto", sans-serif;
    position: relative;
    color: white;

    .smt-history-id {
      display: inline-block;
      background: rgba(255, 255, 255, 0.2);
      padding: 2px 8px;
      font-size: 11px;
      margin: 8px;
      margin-bottom: 2px;
      border-radius: 4px;
    }

    .smt-history-content {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;

      .smt-history-info {
        display: flex;
        flex-direction: column;
        margin: 6px;

        .smt-history-icon {
          margin-top: 4px;
          width: 32px;
          height: 32px;
          border-radius: 6px;
          overflow: hidden;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        .smt-history-title {
          font-size: 14px;
          font-weight: 500;
        }
      }

      .smt-history-details {
        text-align: right;
        margin-right: 8px;
        margin-top: 4px;

        .points {
          font-size: 14px;
          font-weight: 500;
          margin-bottom: 2px;
        }

        .date {
          font-size: 11px;
          opacity: 0.7;
        }
      }
    }

    .status {
      position: absolute;
      top: 8px;
      right: 8px;
      padding: 2px 8px;
      border-radius: 4px;
      font-size: 11px;

      &.succeed {
        background: #28a745;
        color: white;
      }
    }
  }

  @media screen and (max-width: 768px) {
    .smt-history-card {
      min-height: 50px;
      margin-bottom: 6px;

      .smt-history-id {
        padding: 2px 6px;
        font-size: 10px;
        margin: 6px;
      }

      .smt-history-content {
        .smt-history-info {
          margin: 4px;

          .smt-history-icon {
            width: 28px;
            height: 28px;
          }

          .smt-history-title {
            font-size: 12px;
          }
        }

        .smt-history-details {
          margin-right: 6px;

          .points {
            font-size: 12px;
          }

          .date {
            font-size: 10px;
          }
        }
      }

      .status {
        padding: 2px 6px;
        font-size: 10px;
      }
    }
  }

  .tpu__taskDetails__section__area::-webkit-scrollbar {
    display: none;
  }
}

.task-card {
  border-radius: 16px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  margin-bottom: 10px;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    background-color: #f8f9fa;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
  }

  &__header-left {
    display: flex;
    align-items: center;
    flex: 1;
  }

  &__icon {
    width: 52px;
    height: 52px;
    border-radius: 26px;
    margin-right: 14px;
    border: 2px solid #e0e0e0;
  }

  &__info {
    flex: 1;
  }

  &__title {
    font-size: 13px;
    font-weight: 600 !important;
    color: #333;
    margin-bottom: 3px !important;
    line-height: 18px;
    text-wrap: wrap;
    padding-right: 20px !important;
  }

  &__badges {
    display: flex;
    align-items: center;
    justify-content: flex-start;
   
  }

  &__dropdown-btn {
    padding: 3px;
    border-radius: 20px;
    background-color: #e0e0e0;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      background-color: #d0d0d0;
    }
  }

  &__content {
    padding: 14px;
    background-color: #fff;
  }

  &__tabs {
    display: flex;
    margin-bottom: 16px;
    border-radius: 8px;
    background-color: #f0f0f0;
    padding: 4px;
  }

  &__tab {
    flex: 1;
    padding: 8px;
    text-align: center;
    border: none;
    background: none;
    cursor: pointer;
    font-weight: 500;
    color: #666;
    font-family: "Roboto", sans-serif;

    &--active {
      background-color: #3f51b5;
      border-radius: 6px;
      color: #fff;
    }
  }
}

.badge {
  padding: 3px 7px;
  border-radius: 12px;
  margin-right: 1.5px;
  margin-bottom: 4px;
  color: #fff;
  font-weight: 600;
  font-size: 11px;
  display: flex;
  align-items: center;
  // gap: 4px;

  &--amount {
    font-family: "Roboto", sans-serif;
    background-color: #01c44c;
  }

  &--limit {
    font-family: "Roboto", sans-serif;
    background-color: #0d87eb;
  }

  &--approved {
    font-family: "Roboto", sans-serif;
    background-color: #ff9800;
  }
}

.taskId_badge {
  padding: 4px 6px !important;
  border-radius: 10px;
  color: #fff;
  font-weight: 600;
  font-size: 10px;
}

@media screen and (max-width: 768px) {
  .task-card {
    margin-bottom: 8px;

    &__header {
      padding: 8px;
    }

    &__icon {
      width: 40px;
      height: 40px;
      border-radius: 20px;
      margin-right: 8px;
    }

    &__title {
      font-size: 13px;
      line-height: 17px;
      margin-bottom: 3px !important;
      padding-right: 50px !important;
    }

    &__content {
      padding: 10px;
    }
  }

  .badge {
    padding: 2px 4px !important;
    font-size: 9px;
  
  }
  .taskId_badge {
    padding: 2px 4px !important;
    font-size: 9px;
  }
}

.details-card {
  background-color: #f8f9fa;
  border-radius: 8px;
  padding: 12px;
  margin-bottom: 8px !important;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  font-family: "Roboto", sans-serif;

  &__title {
    font-size: 14px;
    font-weight: 600 !important;
    color: #333;
    margin-bottom: 6px !important;
  }

  &__description {
    font-size: 12px !important;
    color: #555;
    // line-height: 10px !important;
    margin-bottom: 8px !important;
  }
}

.action-buttons {
  display: flex;
  justify-content: space-between;
}

.action-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px 10px;
  border-radius: 6px;
  flex: 1;
  border: none;
  cursor: pointer;
  text-decoration: none;
  color: #fff;
  font-weight: 600;
  font-family: "Roboto", sans-serif;
  font-size: 13px;

  svg {
    width: 18px;
    height: 18px;
    margin-right: 6px;
  }

  &--start {
    font-size: small !important;
    background-color: #4caf50;
    margin-right: 8px;

    &:hover {
      background-color: darken(#4caf50, 5%);
    }
  }

  &--share {
    background-color: #2196f3;

    &:hover {
      background-color: darken(#2196f3, 5%);
    }
  }
}
.group-card {
  display: flex;
  background-color: #f8f9fa;
  border-radius: 8px;
  padding: 10px;
  margin-bottom: 6px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  font-family: "Roboto", sans-serif;

  &__title {
    font-size: 13px;
    font-weight: 600 !important;
    color: #333;
    margin-bottom: 4px !important;
  }

  &__description {
    font-size: 12px;
    color: #555;
    line-height: 16px;
    margin-bottom: 8px !important;
  }
}
.group-item {
  display: flex;
  cursor: pointer;
  align-items: center;
  margin-bottom: 10px;

  &__text {
    font-family: "Roboto", sans-serif !important;
    font-size: 12px;
    color: #535353;
    margin-left: 10px !important;
    flex: 1;
  }

  &__icon {
    &--success {
      color: #4caf50;
    }

    &--warning {
      color: #ffc107;
    }

    &--error {
      color: #f44336;
    }
  }
}

.submit-section {
  display: flex;
  justify-content: space-between;
  margin-top: 12px;
  margin-bottom: 12px;
}

.file-picker-btn {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #9c27b0;
  padding: 8px 10px;
  border-radius: 6px;
  margin-right: 6px;
  border: none;
  cursor: pointer;
  color: #fff;
  font-weight: 600;
  font-family: "Roboto", sans-serif;
  font-size: 13px;

  svg {
    width: 18px;
    height: 18px;
    margin-right: 6px;
  }

  &:hover {
    background-color: darken(#9c27b0, 5%);
  }
}

@media screen and (max-width: 768px) {
  .file-picker-btn {
    font-size: 12px;
    padding: 6px 8px;
  }
}

.submit-btn {
  flex: 1;
  background-color: #ff5722;
  padding: 8px 10px;
  border-radius: 6px;
  border: none;
  cursor: pointer;
  color: #fff;
  font-weight: 600;
  font-family: "Roboto", sans-serif;
  font-size: 13px;

  &:hover {
    background-color: darken(#ff5722, 5%);
  }

  &--disabled {
    color: #fff;
    background-color: #ccc;
    cursor: not-allowed;

    &:hover {
      background-color: #ccc;
    }
  }

  .file-picker-btn--disabled {
    background-color: #9c27b0 !important;
    color: #fff !important;
    cursor: not-allowed !important;
  }
}

.file-preview {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f0f0f0;
  border-radius: 6px;
  overflow: hidden;
  margin-top: 16px !important;

  &__image {
    width: 100%;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
  }

  &__info {
    display: flex;
    align-items: center;
    padding: 6px;

    svg {
      width: 18px;
      height: 18px;
      color: #666;
      margin-right: 6px;
    }

    span {
      font-size: 13px;
      color: #666;
    }
  }
}

.info-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  &__text {
    font-family: "Roboto", sans-serif !important;
    font-size: 14px;
    color: #535353;
    margin-left: 0 !important;
    flex: 1;
    line-height: 1.5;
  }

  &__icon {
    &--success {
      color: #4caf50;
    }

    &--warning {
      color: #ffc107;
    }

    &--error {
      color: #f44336;
    }
  }
}

@media screen and (max-width: 768px) {
  .details-card {
    padding: 6px;
    margin-bottom: 6px !important;

    &__title {
      font-size: 13px;
      margin-bottom: 4px !important;
    }

    &__description {
      font-size: 12px;
      line-height: 16px;
    }
  }

  .action-btn {
    padding: 5px 10px;
    font-size: 12px;
   

    svg {
      width: 16px;
      height: 16px;
      margin-right: 4px;
    }
  }

  .file-picker-btn{
    padding: 6px 8px;
    font-size: 10px;
    text-align: center;

    svg {
      width: 16px;
      height: 16px;
      margin-right: 4px;
    }
  }
  

  .submit-btn {
    padding: 6px 8px;
    font-size: 10px;
    text-align: center;
  }
  }

  .info-item {
    margin-bottom: 8px;

    &__text {
      font-size: 11px;
      margin-left: 8px !important;
    }

    svg {
      width: 18px;
      height: 18px;
    }
  }

  .earnings-details-card {
    .info-item__text {
      font-size: 12px;
    }
}

.earnings-details-card {
  .earnings-info-item {
    display: flex;
    align-items: center;
    margin-bottom: 12px;
  }

  .earnings-text-container {
    display: flex;
    flex-direction: column;
    gap: 2px;
    margin-left: 12px;
  }

  .user-type-label {
    font-weight: 600;
  }
}

.badge--users {
  padding: 4px 8px !important;
}

.single-task {
  // background: #fff;
  // border-radius: 12px;
  overflow: hidden;
  margin-bottom: 2px;
  // box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  &__header {
    display: flex;
    align-items: flex-start;
    padding: 12px;
    gap: 12px;
  }

  &__header-left {
    display: flex;
    align-items: flex-start;
    flex: 1;
    gap: 12px;
    min-width: 0;
  }

  &__icon {
    width: 48px;
    height: 48px;
    border-radius: 8px;
    object-fit: cover;
    flex-shrink: 0;
  }

  &__info {
    flex: 1;
    min-width: 0;
  }

  &__title {
    font-size: 14px;
    font-weight: 600;
    color: #000;
    line-height: 20px;
    margin-bottom: 2px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__task-id {
    font-size: 11px;
    color: #666;
    letter-spacing: 0.4px;
    margin-bottom: 2px;
    margin-top: 2px;

    .bullet {
      color: #666;
      padding: 0 4px;
    }
  }

  &__badges {
    display: flex;
    align-items: center;
    white-space: nowrap;
    overflow: hidden;
    flex-shrink: 0;
    
    .badge {
      flex-shrink: 0;
      display: flex;
      align-items: center;
      padding: 2px 2px;
      border-radius: 10px;
      font-size: 10px !important;
      font-weight: 600;
      color: #333;
      gap: 2px;
    }

    .separator {
      flex-shrink: 0;
      color: #666;
      margin: 0 2px;
      font-weight: 500;
    }
  }

  .badge {
    display: flex;
    align-items: center;
    padding: 2px 2px;
    border-radius: 10px;
    font-size: 10px !important;
    font-weight: 600;
    color: #333;
    gap: 2px;

    &--points {
      img {
        width: 14px;
        height: 14px;
      }
    }

    &--time {
      img {
        width: 12px;
        height: 12px;
      }
    }

    &--users {
      font-size: 9.1px;
      letter-spacing: 0.3px;
    }
  }

  .separator {
    color: #666;
    margin: 0 2px;
    font-weight: 500;
  }

  &__dropdown-btn {
    background: #e0e0e0;
    border: none;
    width: 24px;
    height: 24px;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: background-color 0.2s;

    &:hover {
      background: #d0d0d0;
    }
  }

  &__content {
    padding: 6px;
  }

  &__tabs {
    display: flex;
    background: #f0f0f0;
    padding: 3px;
    border-radius: 6px;
    margin-bottom: 8px;

    .tab {
      flex: 1;
      padding: 6px;
      border: none;
      background: none;
      font-size: 12px;
      font-weight: 500;
      color: #666;
      cursor: pointer;
      text-align: center;
      border-radius: 4px;
      transition: all 0.2s;

      &.active {
        background: #3f51b5;
        color: #fff;
      }
    }
  }

  .details-card {
    background: #f8f9fa;
    border-radius: 6px;
    padding: 8px;
    margin-bottom: 6px !important;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);

    h4 {
      font-size: 16px;
      font-weight: 600;
      color: #333;
      margin-bottom: 8px;
    }

    p {
      font-size: 14px;
      color: #555;
      line-height: 20px;
    }
  }

  .action-buttons {
    display: flex;
    gap: 6px;

    .action-btn {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 6px;
      padding: 6px 12px;
      border-radius: 6px;
      border: none;
      cursor: pointer;
      font-size: 12px;
      font-weight: 600;
      color: #fff;
      transition: opacity 0.2s;

      &:disabled {
        opacity: 0.6;
        cursor: not-allowed;
      }

      &.start {
        background: #4CAF50;
      }

      &.share {
        background: #2196F3;
      }
    }
  }

  .submit-section {
    display: flex;
    gap: 6px;
    margin: 16px 0;

    .file-picker {
      flex: 1;

      input[type="file"] {
        display: none;
      }
    }

    .file-picker-btn {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 6px;
      padding: 8px 12px;
      background: #9C27B0;
      color: #fff;
      border-radius: 6px;
      font-size: 12px;
      font-weight: 600;
      cursor: pointer;
      border: none;

      &:disabled {
        background: #ccc;
        cursor: not-allowed;
      }
    }

    .submit-btn {
      flex: 1;
      padding: 8px 12px;
      background: #FF5722;
      color: #fff;
      border: none;
      border-radius: 6px;
      font-size: 12px;
      font-weight: 600;
      cursor: pointer;

      &:disabled {
        background: #ccc;
        cursor: not-allowed;
      }
    }
  }

  .file-preview {
    background: #f0f0f0;
    border-radius: 8px;
    overflow: hidden;
    margin-top: 16px;

    .preview-image {
      width: 100%;
      max-height: 300px;
      object-fit: contain;
    }

    .file-info {
      display: flex;
      align-items: center;
      gap: 8px;
      padding: 8px;
      color: #666;
      font-size: 14px;
    }
  }

  .earnings-section {
    .earnings-card {
      display: flex;
      align-items: flex-start;
      gap: 12px;
      background: #f8f9fa;
      padding: 8px;
      border-radius: 6px;
      margin-bottom: 6px;

      .earnings-info {
        flex: 1;

        p {
          font-size: 14px;
          margin-bottom: 2px;
        }

        .user-type {
          font-weight: 600;

          &.free { color: #2196F3; }
          &.premium { color: #4CAF50; }
          &.premium-plus { color: rgb(224, 170, 62); }
        }
      }
    }
  }

  .info-card {
    display: flex;
    align-items: flex-start;
    gap: 12px;
    background: #f8f9fa;
    padding: 8px;
    border-radius: 6px;
    margin-bottom: 6px;

    p {
      flex: 1;
      font-size: 14px;
      color: #333;
    }
  }
}

@media screen and (max-width: 768px) {
  .single-task {
    &__header {
      padding: 8px;
      gap: 8px;
    }

    &__icon {
      width: 40px;
      height: 40px;
    }

    &__title {
      font-size: 13px;
      line-height: 17px;
    }

    &__badges {
      .badge {
        font-size: 9px !important;
        padding: 2px 4px;
      }
    }
  }
}
