.payment-method-wrapper {
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-color: #f0f2f5; */
    font-family: 'Arial', sans-serif;
  }
  
  .payment-method-container {
    max-width: 450px;
    width: 100%;
    background-color: #ffffff;
    padding: 30px;
    border-radius: 12px;
    box-shadow: 0 10px 18px rgba(0, 0, 0, 0.1);
  }
  
  .section-title {
    text-align: center;
    margin-bottom: 30px;
  }
  
  .section-title h1 {
    font-size: 28px;
    color: #333;
    font-weight: bold;
  }
  
  .payment-methods-list {
    display: flex;
    flex-direction: column;
    gap: 25px;
  }
  
  .payment-method {
    background-color: #f9f9f9;
    border-radius: 8px;
    padding: 20px;
    transition: all 0.3s ease;
  }
  
  .payment-method:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transform: translateY(-2px);
  }
  
  .payment-method h2 {
    font-size: 20px;
    color: #2c3e50;
    margin-bottom: 15px;
    border-bottom: 2px solid #3498db;
    padding-bottom: 5px;
    display: inline-block;
  }
  
  .method-content {
    font-size: 16px;
  }
  
  .method-content p {
    margin: 10px 0;
    color: #34495e;
  }
  
  .qr-container {
    display: flex;
    justify-content: center;
    margin-bottom: 15px;
  }
  
  .qr-code {
    max-width: 150px;
    height: auto;
  }
  
  .qr-address {
    display: flex;
    align-items: center;
    background-color: #ecf0f1;
    border-radius: 6px;
    padding: 5px;
  }
  
  .input-field {
    flex-grow: 1;
    padding: 10px;
    border: none;
    background-color: transparent;
    font-size: 14px;
    color: #2c3e50;
  }
  
  .copy-button {
    background: none;
    border: none;
    cursor: pointer;
    padding: 10px;
    font-size: 20px;
    color: #3498db;
    transition: color 0.3s ease;
  }
  
  .copy-button:hover {
    color: #2980b9;
  }
  
  @media (max-width: 480px) {
    .payment-method-container {
      padding: 20px;
    }
  
    .section-title h1 {
      font-size: 24px;
    }
  
    .payment-method h2 {
      font-size: 18px;
    }
  
    .method-content {
      font-size: 14px;
    }
  }